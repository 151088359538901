<template>
  <Loading></Loading>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import workerMixin from "@/mixins/liff/worker";

export default {
  mixins: [workerMixin],
  components: {
    Loading,
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      let pathName = "LiffWorkerActive"

      if (this.getModuleConfig('terms_page.enable') ?? false) {
        pathName = 'LiffWorkerTerms'
      }

      this.$router.push({
        name: pathName,
        params: {
          orgCode: this.$route.params.orgCode,
        },
        query: this.$route.query,
      })
    }
  }
}
</script>
